import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import mintEventDispatcher from '../../actions/mintEventDispatcher';

export default function withMintEventDispatcher(DecoratedComponent) {
  class ComponentWithMintEventDispatcher extends Component {
    static propTypes = {
      mintEventDispatcher: PropTypes.func.isRequired,
    };

    componentWillUnmount() {
      if (this.removeListeners) {
        this.removeListeners();
      }
    }

    registerListeners = () => {
      this.removeListeners = this.props.mintEventDispatcher();
    };

    render() {
      return (
        <DecoratedComponent
          {...this.props}
          registerListeners={this.registerListeners}
        />
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      mintEventDispatcher: mintEventDispatcher(dispatch),
    };
  }

  return connect(null, mapDispatchToProps)(ComponentWithMintEventDispatcher);
}
